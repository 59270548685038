




























import Vue from 'vue';

export default Vue.extend({
  name: 'Logo',
  props: {
    height: {
      type: String,
      default: '55'
    }
  }
});
