<template>
  <component :is="layout">
    <div class="d-flex bg-white row min-vh-100">
      <div class="container">
        <div
          class="d-flex flex-row justify-content-center align-self-center fadeInUp"
        >
          <div class="col-12 col-md-6 col-lg-5 py-4 py-md-10">
            <div class="text-center mb-6">
              <a class="mb-5 d-block" :href="'/'">
                <Logo />
              </a>
              <h1 class="mb-2 font-weight-bold">
                Sign in
              </h1>
              <p class="text-muted">
                Don't have an account, yet?
                <router-link :to="{ name: 'register' }" class="d-block">
                  Start your free trial
                </router-link>
              </p>
            </div>

            <!--<oauth-button
            :provider="'github'"
            @successfulAuth="oauthSucces"
          />
          <oauth-button
            :provider="'google'"
            @successfulAuth="oauthSucces"
          />
          <oauth-button
            :provider="'facebook'"
            @successfulAuth="oauthSucces"
          />

          <div class="py-5 text-center">
            <p class="text-muted small mb-0">
              or sign in using email
            </p>
          </div>-->

            <p v-if="error" class="text-danger">
              {{ error }}
            </p>

            <div class="form-row">
              <div
                class="form-group col-12"
                :class="{ 'is-invalid': errors.has('email') }"
              >
                <label for="email">Email</label>
                <input
                  id="email"
                  v-model="email"
                  v-validate="{ required: true }"
                  type="email"
                  class="form-control"
                  name="email"
                  autocomplete="email"
                />
                <span v-show="errors.has('email')" class="invalid-feedback">{{
                  errors.first("email")
                }}</span>
              </div>
              <div
                class="form-group col-12"
                :class="{ 'is-invalid': errors.has('password') }"
              >
                <label for="password">Password</label>
                <input
                  id="password"
                  v-model="password"
                  v-validate="{ required: true }"
                  type="password"
                  class="form-control"
                  name="password"
                  autocomplete="password"
                  @keyup.enter="submitForm()"
                />
                <small class="mt-2 d-block text-muted"
                  >Forgot your password?
                  <router-link :to="{ name: 'password.reset' }"
                    >Reset it</router-link
                  >.</small
                >
                <span
                  v-show="errors.has('password')"
                  class="invalid-feedback"
                  >{{ errors.first("password") }}</span
                >
              </div>
              <div class="form-group col-12">
                <button
                  type="submit"
                  class="btn btn-primary btn-block"
                  :disabled="loading"
                  @click="submitForm()"
                >
                  <span
                    v-if="loading"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                  Sign in
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import jsonAPI from "@/api";
import Anonymous from "@/layouts/anonymous.vue";
// import OauthButton from '@/components/Account/OauthButton.vue'
import Logo from "@/components/Svg/Logo.vue";

export default {
  components: {
    Anonymous,
    Logo,
  },
  data() {
    return {
      layout: "Anonymous",
      error: null,
      loading: false,
      email: null,
      password: null,
    };
  },
  methods: {
    async submitForm() {
      this.loading = true;
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          const credentials = {
            email: this.email,
            password: this.password,
          };
          try {
            const respons = await jsonAPI.post("/login", credentials);
            const data = respons.data;
            await this.$store.dispatch("SET_TOKEN", data.token);
            await this.$store.dispatch("LOGIN", data.user);
            await this.$store.dispatch(
              "SET_PERMISSIONS",
              data.user.permissions
            );
            const org = await jsonAPI.get("organization/current");
            await this.$store.dispatch("SET_ORGANIZATION", org.data);
            this.$router.push({ name: "overview" });
          } catch (ext) {
            window.Bus.$emit("flash-message", {
              text: "Invalid credentials, please try another combination",
              type: "error",
            });
            this.loading = false;
          }
        } else {
          this.loading = false;
        }
      });
    },
    oauthSucces() {
      this.$router.push({ name: "overview" });
    },
  },
};
</script>
